<template>
    <tr class="bg-gray-50">
        <td colspan="3" class="text-right py-1 px-2">
            <p class="text-right text-sm font-semibold">
                Differens
            </p>
        </td>
        <td>
            <p class="text-right text-sm font-semibold py-1 px-2">
                {{ creditSummary - debitSummary >= 0 ? creditSummary - debitSummary : "" | swedishNumberFormat }}
            </p>
        </td>
        <td>
            <p class="text-right text-sm font-semibold py-1 px-2">
                {{ debitSummary - creditSummary >= 0 ? debitSummary - creditSummary : "" | swedishNumberFormat }}
            </p>
        </td>
        <!-- <td /> -->
        <td />
    </tr>
</template>
<script>
export default {
    props: {
        debitSummary: {
            type: Number,
            default: 0,
        },
        creditSummary: {
            type: Number,
            default: 0,
        },
    },
};
</script>
<style scoped>
table td {
    border: 1px solid #ddd;
}
</style>
